@import 'hang-tight';

:root {
  /* palette */
  --light: rgb(240, 240, 240);
  --dark: hsla(340, 13%, 5%, 1);
  --grey: hsla(225, 10%, 30%, 1);
  --turquoise: hsla(171, 100%, 75%, 1);
  --cyan: hsla(198, 78%, 64%, 1);

  /* font-sizes */
  --base-font-size: 15px;

  /* spacing */
  --space-small: 12px;
  --space-medium: 18px;
  --space-large: 26px;

  /* animation/transition */
  --animation-duration: 270ms;

  --header-border-width: 7px;
  --header-height: 80px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  outline: 2px solid var(--dark);
}

body {
  font-family: 'Raleway', sans-serif;
  background: var(--light);
  color: var(--grey);
  font-size: var(--base-font-size);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Cutive Mono', monospace;
  color: var(--grey);
}

h1 {
  font-size: 3em;
}

a {
  color: var(--dark);
}

main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
}

img {
  width: 200px;
  display: block;
}

iframe {
  max-width: 100%;
}

.Box {
  box-shadow: 0 0 1px;
  max-width: 80%;
  padding: 1em;
  margin: 0 auto;
}

@media (max-width: 725px) {
  .Box {
    max-width: 95%;
  }

  :root {
    --header-height: 60px;
  }

  header svg.MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }

  header a:focus::before,
  header a:hover::before {
    bottom: 37px;
    padding: 5px 10px;
  }

  header ul li a {
    font-size: 1.2em;
    padding: var(--space-small);
    white-space: nowrap;
  }
}
