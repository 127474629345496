.Cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Card {
  width: 450px;
  margin: 10px;
  max-width: 75%;
  box-shadow: 0 0 1px;
  align-self: stretch;
  transition: box-shadow 350ms;
}

.Card:hover,
.Card:focus-within {
  box-shadow: 0 0 3px;
  transition: box-shadow 350ms;
}

.Card ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.Card li {
  padding: 6px;
  margin: 6px 2px;
  box-shadow: 0 0 1px;
  border-radius: 10px;
}

.Card__source {
  margin: 0 auto;
  padding: 5px;
  display: block;
}

.Card img,
.Card svg {
  width: 50px;
  margin: 15px auto;
  display: inline-block;
}

.Card svg {
  height: 50px;
}
