.About ul {
  margin: 0 auto;
  padding: 1em 3em;
  text-align: left;
  font-size: 1.2em;
}

.About li {
  margin: var(--space-medium) 0;
}
